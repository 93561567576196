import { fetchCrashFileTagValues } from '@/api/report-builder';
import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import { Ref, ref, watch } from 'vue';

export function useTagValuesList(orgIds: Ref<number[]>, tagName: Ref<string>) {
  const values = ref<string[]>([]);
  const tagFetchState = ref<FetchStates>(FetchStates.UNFETCHED);

  const fetchValues = async () => {
    if (!orgIds || orgIds.value.length === 0) {
      values.value = [];
      tagFetchState.value = FetchStates.UNFETCHED;
      return;
    }

    tagFetchState.value = FetchStates.FETCHING;

    try {
      values.value = await fetchCrashFileTagValues(tagName.value, orgIds.value);
      tagFetchState.value = FetchStates.FETCHED;
    } catch (error) {
      alertError(error, 'There was an error fetching tag values.');
      console.error(error);
      tagFetchState.value = FetchStates.UNFETCHED;
    }
  };

  watch(orgIds, fetchValues);
  fetchValues();

  return {
    values,
    tagFetchState,
    fetchValues,
  };
}
