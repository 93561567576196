
import { defineComponent } from 'vue';
import { getConditionName } from '@/api/dto/report';

export default defineComponent({
  name: 'TagQuery',
  props: ['name', 'condition', 'value'],
  setup() {
    return {
      getConditionName,
    };
  },
  data() {
    return {
      hover: false,
    };
  },
});
