import {
  RBTagIdentifier,
  RBTagQueryDTO,
  TagQueryCondition,
  TagTypeEnum,
} from '@/api/dto/report';
import { fetchCrashFileTags, fetchReportTags } from '@/api/report-builder';
import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import { Ref, ref, watch } from 'vue';

export function useTagList(orgIds: Ref<number[]>, tagType: Ref<string>) {
  const allTags = ref<RBTagIdentifier[]>([]);
  const tagFetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const tagQueries = ref<RBTagQueryDTO[]>([]);

  const fetchTags = async () => {
    if (!orgIds || orgIds.value.length === 0) {
      allTags.value = [];
      tagFetchState.value = FetchStates.UNFETCHED;
      return;
    }

    tagFetchState.value = FetchStates.FETCHING;

    try {
      switch (tagType.value) {
        case 'report':
          allTags.value = await fetchReportTags('', orgIds.value);
          break;
        case 'crash-file':
          allTags.value = await fetchCrashFileTags('', orgIds.value);
          break;
        default:
          allTags.value = await fetchReportTags('', orgIds.value);
          break;
      }

      tagFetchState.value = FetchStates.FETCHED;
    } catch (error) {
      alertError(error, 'There was an error fetching report tags.');
      console.error(error);
      tagFetchState.value = FetchStates.UNFETCHED;
    }
  };

  const newTagQuery = (
    key: string,
    type: TagTypeEnum,
    condition: TagQueryCondition,
    value: string,
  ) => {
    let castedVal: string | number | boolean;
    switch (type) {
      case TagTypeEnum.tagTypeNumber:
        if (condition !== TagQueryCondition.range) {
          castedVal = parseFloat(value);
        } else {
          castedVal = value.replaceAll('-', ',').replaceAll(' ', '');
        }
        break;
      case TagTypeEnum.tagTypeString:
        castedVal = value;
        break;
      case TagTypeEnum.tagTypeBoolean:
        castedVal = value === 'true';
        break;
    }

    tagQueries.value.push({
      key: key,
      condition: condition,
      value: castedVal,
    });
  };

  const removeTagQuery = (index: number) => {
    tagQueries.value.splice(index, 1);
  };

  watch(orgIds, fetchTags);
  fetchTags();

  return {
    allTags,
    tagFetchState,
    tagQueries,
    fetchTags,
    newTagQuery,
    removeTagQuery,
  };
}
