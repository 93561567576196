
import { computed, defineComponent, ref, Ref } from 'vue';
import { FetchStates } from '@/helpers/fetch-states';
import TagQuery from './TagQuery.vue';
import {
  getPossibleConditions,
  getConditionName,
  RBTagIdentifier,
  RBTagQueryDTO,
  TagMatch,
  TagQueryCondition,
  TagTypeEnum,
} from '@/api/dto/report';
import { alertError } from '@/helpers/alert-error';
import { OrganizationsModule } from '@/store/organizations';
import { useTagList } from '@/composables/useTagList';
import { useTagValuesList } from '@/composables/useTagValuesList';
import { UserGroups, UserStatuses } from '@/api/dto/user';
import { IntegrationNameMap } from '@/api/integration-name-map';

export default defineComponent({
  name: 'SearchBar',
  components: {
    TagQuery,
  },
  props: {
    useSearch: Boolean,
    useStatusReports: Boolean,
    useTrainingStatus: Boolean,
    useStatusUsers: Boolean,
    useIntegration: Boolean,
    useUserGroups: Boolean,
    useTags: Boolean,
    useOrganizationGroups: Boolean,
    tagType: String,
  },
  emits: [
    'searchChange',
    'statusChange',
    'trainingStatusChange',
    'integrationChange',
    'userGroupsChange',
    'tagQueryChange',
    'tagMatchChange',
    'organizationGroupChange',
  ],
  setup(props) {
    const search = ref<string>('');

    const statusFilter = ref<string>('all');
    const trainingStatusFilter = ref<string>('');
    const integration = ref<string>('all');
    let integrationOptions = [{ key: 'all', value: 'All Integrations' }];
    if (props.useIntegration) {
      integrationOptions = integrationOptions.concat(
        Object.keys(IntegrationNameMap).map(val => {
          return {
            key: val,
            value: IntegrationNameMap[val],
          };
        }),
      );
    }

    const groupFilter = ref<UserGroups[]>([]);

    let allTags = ref(Array<RBTagIdentifier>());
    let newTagQuery = (
      key: string,
      type: TagTypeEnum,
      condition: TagQueryCondition,
      value: string,
    ) => {
      return;
    };
    let removeTagQuery = (index: number) => {
      return;
    };
    let tagQueries = ref(Array<RBTagQueryDTO>());

    if (props.useTags) {
      const orgIds = computed(() => OrganizationsModule.activeOrgIds);
      const tagList = useTagList(orgIds, ref(props.tagType ?? ''));
      allTags = tagList.allTags;
      newTagQuery = tagList.newTagQuery;
      removeTagQuery = tagList.removeTagQuery;
      tagQueries = tagList.tagQueries;
    }

    const newQueryKey = ref<string>('');
    const newQueryType = ref<TagTypeEnum>(TagTypeEnum.tagTypeString);
    const newQueryCondition = ref<TagQueryCondition>(
      TagQueryCondition.equal_to,
    );
    const newQueryValue = ref<string>('');
    const possibleConditions = computed(() =>
      getPossibleConditions(newQueryType.value),
    );
    const boolVals = ['true', 'false'];

    const tagMatch = ref<TagMatch>(TagMatch.some);

    const organizationGroup = ref<string>('');
    let organizationGroupValues = ref(Array<string>());
    if (props.useOrganizationGroups) {
      const orgIds = computed(() => OrganizationsModule.activeOrgIds);
      const organizationGroupsList = useTagValuesList(
        orgIds,
        ref('user_organization_groups'),
      );
      organizationGroupValues = organizationGroupsList.values;
    }

    return {
      FetchStates,
      search,
      statusFilter,
      trainingStatusFilter,
      integration,
      integrationOptions,
      IntegrationNameMap,
      groupFilter,
      allTags,
      newQueryKey,
      newQueryType,
      newQueryCondition,
      newQueryValue,
      possibleConditions,
      boolVals,
      tagQueries,
      tagMatch,
      newTagQuery,
      removeTagQuery,
      getConditionName,
      organizationGroup,
      organizationGroupValues,
    };
  },
  methods: {
    searchChangeHandler(search: string) {
      this.$emit('searchChange', search);
    },
    statusFilterHandler(status: UserStatuses) {
      this.$emit('statusChange', status);
    },
    trainingStatusFilterHandler(status: string) {
      this.$emit('trainingStatusChange', status);
    },
    groupFilterHandler(groups: UserGroups[]) {
      this.$emit('userGroupsChange', groups);
    },
    integrationChangeHandler(int: string) {
      this.$emit('integrationChange', int);
    },
    organizationGroupChangeHandler(group: string) {
      this.$emit('organizationGroupChange', group);
    },
    addNewTagQuery() {
      if (this.newQueryKey.length > 0 && this.newQueryValue.length > 0) {
        this.newTagQuery(
          this.newQueryKey,
          this.newQueryType,
          this.newQueryCondition,
          this.newQueryValue,
        );
        this.$emit('tagQueryChange', this.tagQueries);
        this.resetNewTagQuery();
      } else {
        alertError(
          new Error(),
          'Please fill out all fields of the query before adding it.',
        );
      }
    },
    resetNewTagQuery() {
      this.newQueryKey = '';
      this.newQueryType = TagTypeEnum.tagTypeString;
      this.newQueryCondition = TagQueryCondition.equal_to;
      this.newQueryValue = '';
    },
    handleTagNameSelect() {
      const tagKey = this.newQueryKey;
      const tagIdentifier = this.allTags.find(
        (val: { key: string; type: TagTypeEnum }) => val.key === tagKey,
      );

      if (tagIdentifier) {
        this.newQueryType = tagIdentifier.type;
      } else {
        this.newQueryType = TagTypeEnum.tagTypeString;
        alertError(new Error(), `The tag '${tagKey}' could not be found.`);
      }

      this.newQueryCondition = TagQueryCondition.equal_to;
      this.newQueryValue = '';
    },
    tagMatchHandler(match: TagMatch) {
      this.$emit('tagMatchChange', match);
    },
  },
});
